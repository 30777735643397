import React, { useState, useEffect } from "react";
import DnsRecordsTable from "../../component/Dashboard/DnsRecordsTable";
import ReverseDnsTable from "../../component/Dashboard/ReverseDNSTable";
import ShodanResults from "../../component/Dashboard/ShodanTable";
import PortsTable from "../../component/Dashboard/PortsTable";
import CollapsibleSection from "../../component/Dashboard/CollapsibleSection";
import SSLInfo from "../../component/Dashboard/SSLInfo";
import HttpInfoResult from "../../component/Dashboard/HttpInfo";
import { fetchDomainCheckData } from "../../apis/domain_check";
import CompanyScore from "../../component/Dashboard/CompanyScore";

export default function Dashboard({ formData }) {
  const [collapsedSections, setCollapsedSections] = useState({
    dnsRecords: true,
    reverseDns: true,
    shodanResults: true,
    portsResults: true,
    sslInformation: true,
    httpInformation: true,
  });

  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [companyScore, setCompanyScore] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("Form data received:", formData);

    if (!formData?.domain) {
      setError("Domain name is required");
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await fetchDomainCheckData(formData.domain);

        console.log("API response:", data);

        setCompanyData({
          dnsRecords: data?.dns_records || {},
          reverseDns: data?.reverse_dns || {},
          shodanResults: data?.shodan_results || {},
          portsResults: data?.ports || {},
          sslResults: data?.ssl || {},
          httpAccess: data?.http_access || {},
          httpsAccess: data?.https_access || {},
          httpsHeader: data?.https_headers || {},
        });
        setCompanyScore(data?.company_score || null);
        setError(null); // Clear previous errors
      } catch (err) {
        console.error("Error fetching company data:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [formData]);

  useEffect(() => {
    const rawDomain = formData?.domain || "Unknown Company";

    // Remove protocol (http, https), www, and domain extensions
    const cleanedDomain = rawDomain
      .replace(/(https?:\/\/)?(www\.)?/, "") // Remove http(s):// and www.
      .split(".")[0]; // Take the part before the first dot

    // Capitalize the first letter
    const capitalizedDomain =
      cleanedDomain.charAt(0).toUpperCase() + cleanedDomain.slice(1);

    setCompanyName(capitalizedDomain || "Unknown Company");
  }, [formData]);

  const toggleSection = (sectionName) => {
    setCollapsedSections((prevState) => ({
      ...prevState,
      [sectionName]: !prevState[sectionName],
    }));
  };

  if (!formData?.domain) {
    return <div>Error: Domain name is required</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!companyData) {
    return <div>No data available for the provided domain</div>;
  }

  return (
    <section className="relative table w-full py-15 lg:py-20 bg-gray-100">
      <div className="dashboard-container p-6 bg-white shadow rounded-lg">
        {/* Company Name Section */}
        <div className="company-name mb-4">
          <h1 className="text-3xl font-bold text-gray-800">{companyName}</h1>
        </div>

        {/* Company Score Section */}
        <CompanyScore score={companyScore} />

        {/* DNS Records Section */}
        <CollapsibleSection
          title="DNS Records"
          isCollapsed={collapsedSections.dnsRecords}
          toggleCollapse={() => toggleSection("dnsRecords")}
        >
          <DnsRecordsTable dnsRecords={companyData.dnsRecords} />
        </CollapsibleSection>

        {/* Reverse DNS Section */}
        <CollapsibleSection
          title="Reverse DNS Records"
          isCollapsed={collapsedSections.reverseDns}
          toggleCollapse={() => toggleSection("reverseDns")}
        >
          <ReverseDnsTable reverseDnsData={companyData.reverseDns} />
        </CollapsibleSection>

        {/* Shodan Results Section */}
        <CollapsibleSection
          title="Shodan Results"
          isCollapsed={collapsedSections.shodanResults}
          toggleCollapse={() => toggleSection("shodanResults")}
        >
          <ShodanResults results={companyData.shodanResults} />
        </CollapsibleSection>

        {/* Ports Results Section */}
        <CollapsibleSection
          title="Ports Results"
          isCollapsed={collapsedSections.portsResults}
          toggleCollapse={() => toggleSection("portsResults")}
        >
          <PortsTable results={companyData.portsResults} />
        </CollapsibleSection>

        {/* SSL Information Section */}
        <CollapsibleSection
          title="SSL Information"
          isCollapsed={collapsedSections.sslInformation}
          toggleCollapse={() => toggleSection("sslInformation")}
        >
          <SSLInfo results={companyData.sslResults} />
        </CollapsibleSection>

        {/* HTTP Information Section */}
        <CollapsibleSection
          title="HTTP Information"
          isCollapsed={collapsedSections.httpInformation}
          toggleCollapse={() => toggleSection("httpInformation")}
        >
          <HttpInfoResult
            httpsAccess={companyData.httpsAccess["message"]}
            httpAccess={companyData.httpAccess["message"]}
            httpsHeader={companyData.httpsHeader}
          />
        </CollapsibleSection>
      </div>
    </section>
  );
}
