import React from "react";

const ShodanResults = ({ results }) => {
  // Validate if results is a valid object
  if (!results || typeof results !== "object" || Array.isArray(results)) {
    console.warn("Invalid results data received:", results);
    return <div className="text-center text-gray-600">No data available</div>;
  }

  // Check if there are any entries in the results object
  const resultEntries = Object.entries(results);
  if (resultEntries.length === 0) {
    return <div className="text-center text-gray-600">No data to display</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <table className="min-w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left text-gray-800 font-medium border border-gray-300">
              IP Address
            </th>
            <th className="px-4 py-2 text-left text-gray-800 font-medium border border-gray-300">
              Details
            </th>
            <th className="px-4 py-2 text-left text-gray-800 font-medium border border-gray-300">
              Data
            </th>
          </tr>
        </thead>
        <tbody>
          {resultEntries.map(([ipKey, ipData]) => {
            if (!ipData || typeof ipData !== "object") {
              console.warn(`Invalid data for IP: ${ipKey}`, ipData);
              return (
                <tr key={ipKey} className="hover:bg-gray-100">
                  <td
                    colSpan="3"
                    className="px-4 py-2 text-sm text-center text-gray-600 border border-gray-300"
                  >
                    Invalid data for {ipKey}
                  </td>
                </tr>
              );
            }

            return (
              <tr key={ipKey} className="hover:bg-gray-100">
                <td className="px-4 py-2 text-sm font-medium text-gray-900 border border-gray-300">
                  {ipKey || "N/A"}
                </td>
                <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">
                  <p>
                    <strong>Region:</strong>{" "}
                    {ipData.fetchDomainCheckData || "N/A"}
                  </p>
                  <p>
                    <strong>Country:</strong> {ipData.country_name || "N/A"} (
                    {ipData.country_code || "N/A"})
                  </p>
                  <p>
                    <strong>City:</strong> {ipData.city || "N/A"}
                  </p>
                  <p>
                    <strong>ISP:</strong> {ipData.isp || "N/A"}
                  </p>
                  <p>
                    <strong>Organization:</strong> {ipData.org || "N/A"}
                  </p>
                  <p>
                    <strong>Latitude:</strong> {ipData.latitude || "N/A"},{" "}
                    <strong>Longitude:</strong> {ipData.longitude || "N/A"}
                  </p>
                  <p>
                    <strong>Hostnames:</strong>{" "}
                    {Array.isArray(ipData.hostnames) && ipData.hostnames.length
                      ? ipData.hostnames.join(", ")
                      : "None"}
                  </p>
                  <p>
                    <strong>Domains:</strong>{" "}
                    {Array.isArray(ipData.domains) && ipData.domains.length
                      ? ipData.domains.join(", ")
                      : "None"}
                  </p>
                  <p>
                    <strong>Ports:</strong>{" "}
                    {Array.isArray(ipData.ports) && ipData.ports.length
                      ? ipData.ports.join(", ")
                      : "None"}
                  </p>
                </td>
                <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">
                  {Array.isArray(ipData.data) && ipData.data.length ? (
                    ipData.data.map((entry, index) => (
                      <div key={index} className="mb-4">
                        <p>
                          <strong>Port:</strong> {entry.port || "N/A"}
                        </p>
                        <p>
                          <strong>Transport:</strong> {entry.transport || "N/A"}
                        </p>
                        <p>
                          <strong>Product:</strong> {entry.product || "N/A"}
                        </p>
                        <p>
                          <strong>HTTP Status:</strong>{" "}
                          {entry.http?.status || "N/A"}
                        </p>
                        <p>
                          <strong>Server:</strong> {entry.http?.server || "N/A"}
                        </p>
                        <p>
                          <strong>Title:</strong> {entry.http?.title || "N/A"}
                        </p>
                        <p>
                          <strong>Timestamp:</strong> {entry.timestamp || "N/A"}
                        </p>
                        <details>
                          <summary className="text-blue-500 cursor-pointer">
                            View Raw HTTP Headers
                          </summary>
                          <pre className="bg-gray-100 p-2 rounded text-sm overflow-x-auto max-w-full whitespace-pre-wrap break-words">
                            {entry.data || "N/A"}
                          </pre>
                        </details>
                      </div>
                    ))
                  ) : (
                    <div>No detailed data available</div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ShodanResults;
