import React, { useEffect, useState } from "react";
import Navbar from "../../component/Navbar/navbar";
import Dashboard from "./dashboard";
import InputForm from "./company_input";
import Questions from "./questions";
import { BACKEND_URL } from "../../data/urls";

export default function Demo() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
  }, []);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [formData, setFormData] = useState({});
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    // Fetch the list of questions from the backend
    console.log("backend url", BACKEND_URL);
    const getQuestions = async () => {
      // const data = await fetch();
      // setQuestions(data);
      setQuestions([
        {
          name: "question1",
          label: "Do you enjoy programming?",
          type: "Yes/No",
        },
        {
          name: "question2",
          label: "How many years of experience do you have?",
          type: "Numeric",
        },
      ]);
    };
    getQuestions();
  }, []);

  const handleCompanyFormSubmit = (data) => {
    setFormData({ ...formData, ...data });
    setCurrentPageIndex(currentPageIndex + 1);
  };

  const handleQuestionsFormSubmit = (data) => {
    setFormData({ ...formData, ...data });
    setCurrentPageIndex(currentPageIndex + 1);
  };

  const goBack = () => {
    if (currentPageIndex === 1) setCurrentPageIndex(currentPageIndex - 1);
  };

  const pages = [
    <InputForm onSubmit={handleCompanyFormSubmit} />,
    // <Questions onSubmit={handleQuestionsFormSubmit} questions={questions} />,
    <Dashboard formData={formData} />,
  ];

  return (
    <>
      <Navbar />
      <section className="relative table w-full py-15 py-20 bg-center bg-no-repeat bg-cover">
        <div className="flex justify-center items-center my-4">
          {currentPageIndex === 1 && (
            <button onClick={goBack} className="btn btn-secondary">
              Back
            </button>
          )}
          {/* Step Indicator */}
          {pages.map((_, index) => (
            <div
              key={index}
              // onClick={() => {
              //   if (index < currentPageIndex) setCurrentPageIndex(index);
              // }} // Navigate to the clicked step
              className={` w-8 h-8 flex items-center justify-center rounded-full text-white mx-2 ${
                index === currentPageIndex
                  ? "bg-blue-600 font-bold"
                  : "bg-gray-300 hover:bg-gray-400"
              }`}
            >
              {index + 1}
            </div>
          ))}
          {/* {currentPageIndex < pages.length - 1 && (
            <button
              onClick={() => handleFormSubmit({})}
              className="btn btn-primary"
            >
              Next
            </button>
          )} */}
        </div>
        <div
          className={`flex transition-transform duration-1000 ease-in-out`}
          style={{ transform: `translateX(-${currentPageIndex * 100}%)` }}
        >
          {pages.map((Page, index) => (
            <div
              key={index}
              className="flex-shrink-0"
              style={{ flexBasis: "100%" }}
            >
              {Page}
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
