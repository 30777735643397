import React, { useState } from "react";

export default function PortsTable({ results }) {
  // Initialize all hosts as collapsed
  const [collapsedHosts, setCollapsedHosts] = useState(
    Object.keys(results).reduce((acc, host) => ({ ...acc, [host]: true }), {})
  );

  const toggleHost = (host) => {
    setCollapsedHosts((prevState) => ({
      ...prevState,
      [host]: !prevState[host],
    }));
  };

  return (
    <div className="p-6 bg-gray-100">
      {Object.keys(results).map((host) => (
        <div key={host} className="mb-6 bg-white p-4 shadow rounded-lg">
          <h2
            className="text-xl font-semibold mb-4 cursor-pointer text-gray-800 flex items-center"
            onClick={() => toggleHost(host)}
          >
            {host}
            <span className="ml-2 text-sm">
              {collapsedHosts[host] ? "▼" : "▲"}
            </span>
          </h2>
          {!collapsedHosts[host] && (
            <table className="table-auto w-full text-left border-collapse border border-gray-300">
              <thead className="bg-gray-200">
                <tr>
                  <th className="border border-gray-300 px-4 py-2">Port</th>
                  <th className="border border-gray-300 px-4 py-2">Protocol</th>
                  <th className="border border-gray-300 px-4 py-2">State</th>
                  <th className="border border-gray-300 px-4 py-2">Name</th>
                </tr>
              </thead>
              <tbody>
                {results[host].map((portInfo, index) => (
                  <tr
                    key={index}
                    className={`border-t border-gray-300 ${
                      portInfo.state === "open" ? "bg-red-100" : ""
                    }`}
                  >
                    <td className="border border-gray-300 px-4 py-2">
                      {portInfo.port}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {portInfo.protocol}
                    </td>
                    <td
                      className={`border border-gray-300 px-4 py-2 ${
                        portInfo.state === "open"
                          ? "text-red-600 font-bold"
                          : ""
                      }`}
                    >
                      {portInfo.state}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {portInfo.name}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      ))}
    </div>
  );
}
