import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";

import slide02 from "../../assets/images/blog/slide02.jpg";

import Navbar from "../../component/Navbar/navbar";
import Footer from "../../component/Footer/footer";
import BlogComment from "../../component/blogComment";
import BlogUserDetail from "../../component/blogUserDetail";
import { blogData } from "../../data/data";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BACKEND_IMAGE_URL } from "../../data/urls";
export default function BlogDetail() {
  const params = useParams();
  const id = params.id;
  const data = blogData.find((blog) => blog.id === parseInt(id));
  const location = useLocation();
  const blog = location.state?.blog;
  console.log(blog);

  const blog_content = blog.content.split("\\n");
  const blog_elements = [];
  let current_image_index = 0;
  for (let i = 0; i < blog_content.length; i++) {
    const block = blog_content[i].trim();

    if (
      block.startsWith("%IMAGE_PLACEHOLDER%") &&
      current_image_index < blog.images.length
    ) {
      blog_elements.push(
        <div
          className="col-span-12 md:col-span-6 md:col-start-4"
          key={`image-wrapper-${i}`}
        >
          <img
            src={`${BACKEND_IMAGE_URL}/${blog.images[current_image_index++]}`}
            alt={`blog_${current_image_index}`}
            className="rounded-md w-full h-auto"
          />
          <n></n>
        </div>
      );
    } else if (block.startsWith("###")) {
      blog_elements.push(
        <div className="col-span-12 md:col-span-12" key={`h3-wrapper-${i}`}>
          <h3 className="text-xl font-bold leading-tight text-gray-500">
            {block.slice(3)}
          </h3>
          <n></n>
        </div>
      );
    } else if (block.startsWith("##")) {
      blog_elements.push(
        <div className="col-span-12 md:col-span-12" key={`h2-wrapper-${i}`}>
          <h2 className="text-2xl font-bold leading-tight text-gray-500">
            {block.slice(3)}
          </h2>
          <n></n>
        </div>
      );
    } else if (block.startsWith("#")) {
      blog_elements.push(
        <div className="col-span-12 md:col-span-12" key={`h1-wrapper-${i}`}>
          <h1 className="text-3xl font-bold leading-tight text-gray-500">
            {block.slice(3)}
          </h1>
          <n></n>
        </div>
      );
    } else if (block.startsWith('"""') && block.endsWith('"""')) {
      blog_elements.push(
        <div className="col-span-12 md:col-span-12" key={`p-wrapper-${i}`}>
          <p className="text-slate-400 italic border-x-4 border-indigo-600 rounded-ss-xl rounded-ee-xl mt-3 p-3">
            {block.replace(/"/g, "")}
          </p>
          <n></n>
        </div>
      );
    } else {
      blog_elements.push(
        <div className="col-span-12 md:col-span-12" key={`p-wrapper-${i}`}>
          <p className="text-slate-400">{block}</p>
          <n></n>
        </div>
      );
    }
  }

  return (
    <>
      <Navbar navClass="nav-light" />

      <section className="relative table w-full py-32 lg:py-36 bg-[url('../../assets/images/blog/bg.jpg')] bg-center bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-black opacity-80"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="mb-3 text-3xl leading-normal font-medium text-white">
              {blog.title}
            </h3>

            <ul className="list-none mt-6">
              {/* <li className="inline-block font-semibold text-white/50 mx-5">
                {" "}
                <span className="text-white block">Author :</span>{" "}
                <span className="block">
                  {blog?.author ? blog?.author : "Calvin Carlo"}
                </span>
              </li> */}
              <li className="inline-block font-semibold text-white/50 mx-5">
                {" "}
                <span className="text-white block">Date :</span>{" "}
                <span className="block">
                  {blog?.created_at
                    ? new Date(blog?.created_at).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        // hour: "2-digit",
                        // minute: "2-digit",
                        // second: "2-digit",
                      })
                    : "23th May, 2022"}
                </span>
              </li>
              <li className="inline-block font-semibold text-white/50 mx-5">
                {" "}
                <span className="text-white block">Time :</span>{" "}
                <span className="block">
                  {Math.ceil(blog.content.length / 800)} Min Read
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className=" tracking-[0.5px] mb-0 inline-flex space-x-1">
            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">Armorex</Link>
            </li>
            <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <MdKeyboardArrowRight className="text-xl" />
            </li>
            <li
              className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"
              aria-current="page"
            >
              <Link to="/blog">Blog</Link>
            </li>
            <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <MdKeyboardArrowRight className="text-xl" />
            </li>
            <li
              className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Blog Post Details
            </li>
          </ul>
        </div>
      </section>

      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
            <div className="lg:col-span-8 md:col-span-6">
              <div className="p-6 rounded-md shadow dark:shadow-gray-800">
                <img
                  src={
                    blog?.thumbnail_url
                      ? `${BACKEND_IMAGE_URL}/${blog?.thumbnail_url}`
                      : slide02
                  }
                  className="rounded-md w-full h-auto"
                  alt=""
                />

                <div className="mt-6">{blog_elements}</div>
              </div>

              {/* <BlogComment /> */}
            </div>

            <div className="lg:col-span-4 md:col-span-6">
              <BlogUserDetail client={data?.client} name={blog?.author} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
