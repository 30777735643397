import React from "react";

const ReverseDnsTable = ({ reverseDnsData }) => {
  // Validate if reverseDnsData is a valid object
  if (
    !reverseDnsData ||
    typeof reverseDnsData !== "object" ||
    Array.isArray(reverseDnsData)
  ) {
    console.warn("Invalid reverseDnsData provided:", reverseDnsData);
    return <div className="text-center text-gray-600">No data available</div>;
  }

  // Check if there are any entries in the reverseDnsData object
  const dataEntries = Object.entries(reverseDnsData);
  if (dataEntries.length === 0) {
    return (
      <div className="text-center text-gray-600">
        No reverse DNS data to display
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead className="bg-gray-200">
          <tr>
            <th className="px-4 py-2 text-left font-semibold text-gray-700 border border-gray-300">
              IP Address
            </th>
            <th className="px-4 py-2 text-left font-semibold text-gray-700 border border-gray-300">
              Domain Name
            </th>
            <th className="px-4 py-2 text-left font-semibold text-gray-700 border border-gray-300">
              Reverse Name
            </th>
            <th className="px-4 py-2 text-left font-semibold text-gray-700 border border-gray-300">
              PTR Records
            </th>
            <th className="px-4 py-2 text-left font-semibold text-gray-700 border border-gray-300">
              Name Servers (NS)
            </th>
          </tr>
        </thead>
        <tbody>
          {dataEntries.map(([ip, details], index) => {
            if (!details || typeof details !== "object") {
              console.warn(`Invalid details for IP: ${ip}`, details);
              return (
                <tr
                  key={ip}
                  className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
                >
                  <td
                    colSpan="5"
                    className="px-4 py-2 text-sm text-center text-gray-600 border border-gray-300"
                  >
                    Invalid data for {ip}
                  </td>
                </tr>
              );
            }

            return (
              <tr
                key={ip}
                className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
              >
                <td className="px-4 py-2 text-sm font-medium text-gray-900 border border-gray-300">
                  {ip || "N/A"}
                </td>
                <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">
                  {details.domain_name || "N/A"}
                </td>
                <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">
                  {details.reverse_name || "N/A"}
                </td>
                <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">
                  {Array.isArray(details.PTR) && details.PTR.length > 0 ? (
                    <ol className="list-decimal pl-6 space-y-1">
                      {details.PTR.map((ptr, idx) => (
                        <li key={idx}>{ptr}</li>
                      ))}
                    </ol>
                  ) : (
                    "None"
                  )}
                </td>
                <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">
                  {Array.isArray(details.NS) && details.NS.length > 0 ? (
                    <ol className="list-decimal pl-6 space-y-1">
                      {details.NS.map((ns, idx) => (
                        <li key={idx}>{ns}</li>
                      ))}
                    </ol>
                  ) : (
                    "None"
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ReverseDnsTable;
