import React from "react";

export default function DnsRecordsTable({ dnsRecords }) {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead className="bg-gray-200">
          <tr>
            <th className="px-4 py-2 text-left font-semibold text-gray-700 border border-gray-300">
              Record Type
            </th>
            <th className="px-4 py-2 text-left font-semibold text-gray-700 border border-gray-300">
              Values
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(dnsRecords).map(([recordType, values], index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
            >
              <td className="px-4 py-2 text-sm font-medium text-gray-900 border border-gray-300">
                {recordType}
              </td>
              <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">
                {Array.isArray(values) ? (
                  <ol className="list-decimal pl-6 space-y-1">
                    {values.map((value, idx) => (
                      <li key={idx}>{value}</li>
                    ))}
                  </ol>
                ) : (
                  values || "N/A"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
