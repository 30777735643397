import React from "react";

const SSLInfo = ({ results }) => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-xl rounded-lg border border-gray-100">
      <h3 className="text-3xl font-bold text-gray-800 mb-6">
        SSL Certificate Information
      </h3>

      {/* Issuer Section */}
      <div className="mb-6">
        <h4 className="text-2xl font-semibold text-gray-700 mb-4">Issuer</h4>
        <ul className="list-none space-y-3">
          <li className="flex items-center text-gray-600">
            <strong className="font-medium text-gray-700 w-36">Country:</strong>
            <span className="pl-2">{results.Issuer.countryName}</span>
          </li>
          <li className="flex items-center text-gray-600">
            <strong className="font-medium text-gray-700 w-36">
              Organization:
            </strong>
            <span className="pl-2">{results.Issuer.organizationName}</span>
          </li>
          <li className="flex items-center text-gray-600">
            <strong className="font-medium text-gray-700 w-36">
              Common Name:
            </strong>
            <span className="pl-2">{results.Issuer.commonName}</span>
          </li>
        </ul>
      </div>

      {/* Subject Section */}
      <div className="mb-6">
        <h4 className="text-2xl font-semibold text-gray-700 mb-4">Subject</h4>
        <ul className="list-none space-y-3">
          <li className="flex items-center text-gray-600">
            <strong className="font-medium text-gray-700 w-36">
              Common Name:
            </strong>
            <span className="pl-2">{results.Subject.commonName}</span>
          </li>
        </ul>
      </div>

      {/* Expiration Date Section */}
      <div className="mb-6">
        <h4 className="text-2xl font-semibold text-gray-700 mb-4">
          Expiration Date
        </h4>
        <p className="text-gray-600 text-lg">{results["Expiration Date"]}</p>
      </div>

      {/* SANs Section */}
      <div className="mb-6">
        <h4 className="text-2xl font-semibold text-gray-700 mb-4">
          Subject Alternative Names (SANs)
        </h4>
        <ul className="list-none space-y-3">
          {results.SANs.map((san, index) => (
            <li key={index} className="flex items-center text-gray-600">
              <strong className="font-medium text-gray-700 w-36">
                {san[0]}:
              </strong>
              <span className="pl-2">{san[1]}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SSLInfo;
