import React from "react";

export default function CollapsibleSection({
  title,
  isCollapsed,
  toggleCollapse,
  children,
}) {
  return (
    <div className="mb-8">
      <h1
        className="text-2xl font-bold mb-6 text-gray-800 cursor-pointer flex items-center"
        onClick={toggleCollapse}
      >
        {title}
        <span className="ml-2 text-sm">{isCollapsed ? "▼" : "▲"}</span>
      </h1>
      {!isCollapsed && <div>{children}</div>}
    </div>
  );
}
