import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const InputForm = ({ onSubmit }) => {
  // Validation schema using Yup
  const validationSchema = Yup.object({
    domain: Yup.string()
      .required("Domain is required.")
      .matches(
        /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid domain name."
      ),
    industry: Yup.string().required("Please select an industry."),
  });

  // Dropdown options
  const industryOptions = [
    "Financial Services",
    "Healthcare",
    "Retail",
    "Technology",
    "Manufacturing",
    "Legal Services",
    "Education",
    "Energy and Utilities",
    "Telecommunications",
    "Government/Public Sector",
  ];

  return (
    <div className="container-fluid relative">
      <div className="grid lg:grid-cols-12 grid-cols-1">
        <div className="lg:col-span-10 lg:col-start-2 col-span-12">
          <div className="layout-specing">
            <Formik
              initialValues={{
                domain: "monzo.com",
                industry: "Financial Services",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                onSubmit(values);
                resetForm();
              }}
            >
              {({ isSubmitting }) => (
                <Form className="p-4 border rounded shadow-md">
                  {/* Domain Input */}
                  <div className="mb-4">
                    <label
                      htmlFor="domain"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Domain
                    </label>
                    <Field
                      id="domain"
                      name="domain"
                      type="text"
                      className="mt-1 block w-full border rounded-md p-2"
                      placeholder="Enter your domain (e.g., example.com)"
                    />
                    <ErrorMessage
                      name="domain"
                      component="p"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  {/* Industry Dropdown */}
                  <div className="mb-4">
                    <label
                      htmlFor="industry"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Industry
                    </label>
                    <Field
                      as="select"
                      id="industry"
                      name="industry"
                      className="mt-1 block w-full border rounded-md p-2"
                    >
                      <option value="" disabled>
                        Select an industry
                      </option>
                      {industryOptions.map((industry, index) => (
                        <option key={index} value={industry}>
                          {industry}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="industry"
                      component="p"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  {/* Submit Button */}
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-600 text-white font-medium rounded hover:bg-blue-700"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputForm;
