import React from "react";

const HttpInfoResult = ({ httpsAccess, httpAccess, httpsHeader }) => {
  console.log(httpsAccess, httpAccess, httpsHeader);
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-xl rounded-lg border border-gray-100">
      <h3 className="text-3xl font-bold text-gray-800 mb-6">
        Web Service Access Information
      </h3>

      {/* HTTP Access Section */}
      <div className="mb-6">
        <h4 className="text-2xl font-semibold text-gray-700 mb-4">
          HTTP Access
        </h4>
        <p className="text-gray-600">{httpAccess}</p>
      </div>

      {/* HTTPS Access Section */}
      <div className="mb-6">
        <h4 className="text-2xl font-semibold text-gray-700 mb-4">
          HTTPS Access
        </h4>
        <p className="text-gray-600">{httpsAccess}</p>
      </div>

      {/* HTTPS Headers Section */}
      <div className="mb-6">
        <h4 className="text-2xl font-semibold text-gray-700 mb-4">
          HTTPS Headers
        </h4>
        <ul className="list-none space-y-3">
          {Object.entries(httpsHeader).map(([key, value]) => (
            <li key={key} className="text-gray-600">
              <strong className="font-medium text-gray-700">{key}:</strong>{" "}
              {value}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default HttpInfoResult;
