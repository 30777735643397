import React from "react";

export default function CompanyScore({ score }) {
  const isHighScore = score >= 50;

  return (
    <div className="company-score flex items-center gap-4 p-4 border border-gray-300 bg-gray-50 rounded-md shadow-sm mb-8">
      <h2 className="text-xl font-bold text-gray-800">Company Score:</h2>
      <p
        className={`text-xl font-semibold px-3 py-1 rounded-md ${
          isHighScore
            ? "text-green-500 bg-green-100"
            : "text-red-500 bg-red-100"
        }`}
      >
        {score ? `${score} %` : "No score available"}
      </p>
    </div>
  );
}
