export const fetchDomainCheckData = async (domain) => {
  try {
    const apiUrl = process.env.REACT_APP_API_URL; // Get API URL from environment variable

    const response = await fetch(
      `${apiUrl}/companies/check-score?domain=${domain}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data; // Return the fetched data
  } catch (err) {
    throw new Error(err.message); // Throw error for the calling function to handle
  }
};
